$primary-color: #3498db;

.about-us-container {
  display: flex;
  flex-direction: column;
}

.about-us-content {
  justify-content: space-between;
}

.about-us-text {
  width: 100%;
  text-align: center;

  h1 {
    margin: 2% 10%;
    font-size: 40px;
  }

  p {
    line-height: 2;
    font-size: 20px;
    margin: 2% 10%;
    text-align: justify;
    @media (max-width:480px) {
      line-height: 1.2;
    font-size: 14px;

      
    }
  }
}

.about-us-image {
  background: url("../../utils/img/i.webp") center/cover no-repeat;
  width: 100vw;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  h1 {
    color: $primary-color;
    font-size: 70px;
    transition: transform 0.3s ease-in-out;
    z-index: 10;
   
  }

  p {
    line-height: 2;
    font-size: 20px;
    margin: 2% 10%;
    @media (max-width:480px) {
      line-height: 1.2;
    font-size: 14px; 
    }
  }
}

.team-section {
  text-align: center;
  margin: 2% 10%;

  .team-cards {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .team-card {
      width: 90%;
      margin: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      transition: transform 0.3s ease-in-out;
      margin: 2%;

    

      .head {
        display: flex;
        .profile {
          width: 40%;
           @media (max-width:480px) {
              width: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
           }
          img {
            max-width: 80%;
            border-radius: 5px;
            margin-bottom: 10px;
            transition: transform 0.3s ease-in-out;
            @media (max-width:480px) {
              margin-left: 30%;
            }
          
          }
        }

        .team-member {
          margin-top: 20%;
          @media (max-width:480px) {
           margin-top: 0;
          }
          h2 {
            font-size: 40px;
            @media (max-width:480px) {
              
            font-size: 24px;
        
              
            }
          }

          p {
            font-size: 20px;
            font-style: italic;
            @media (max-width:480px) {
              line-height: 1.2;
            font-size: 14px;
        
              
            }
          }
        }
      }

      .content {
        width: 100%;

        p {
          line-height: 1.5;
          font-size: 20px;
          margin: 2%;
          text-align: justify;
          @media (max-width:480px) {
            line-height: 1.2;
          font-size: 14px;
      
            
          }
        }
      }
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }
  }


  @media  (max-width: 768px) {
    .about-us-text h1 {
      font-size: 30px;
    }

    .about-us-text p {
      font-size: 16px;
    }

    .about-us-image h1 {
      font-size: 50px;
    }

    .team-card {
      width: 100%;
    }
  }

  @media  (max-width: 480px) {
    .about-us-text h1 {
      font-size: 24px;
    }

    .about-us-text p {
      font-size: 14px;
    }

    .about-us-image h1 {
      font-size: 40px;
    }

    .team-card {
      width: 100%;
      .head{
        flex-direction: column;
        .profile {
          
          width: 100%;

          img {
            max-width: 246%;
           height: 100%;
          }
        }
        .team-member {
          margin-top: 0%;
          h2 {
            font-size: 10px;
          }

          p {
            font-size: 10px;
            font-style: italic;
          }
        }
      }
    }
  }
}
