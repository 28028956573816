$primary-color: #3498db;
.main {
  .bannerImg {
    background: url("../../utils/img/background3.jpg")
      center/cover no-repeat;
    width: 100vw;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
      color: #3498db;
      font-size: 70px;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .maps {
    width: 100%;
    overflow: hidden; 
    position: relative; 
    margin-top: 20px; 
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .account {
    display: flex;
    justify-content: space-evenly;
    width: 98vw;
    margin: 2%;
    @media (max-width: 730px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media(max-width:460px){
      margin: 0;
    }
    .contact-info {
      margin-top: 20px;
    //   display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
      .info {
        display: flex;
        @media(max-width:460px){
        width: 300px;
        margin: 0;
        font-size: small;
        padding-right: 15px;
        }
        .icon {
          margin: 20px;
          font-size: 40px;
          color: $primary-color;
          @media (max-width:460px) {
            margin: 5px;
          }
        }
      }
    }
    .contact-form {
      width: 700px;
      @media (max-width: 1160px) {
        width: 50%;
      }
      @media (max-width: 912px) {
        width: 30%;
      }
      @media (max-width: 730px) {
        width: 80%;
      }
      h2 {
        text-align: center;
        margin-bottom: 20px;
      }

      form {
        display: flex;
        flex-direction: column;

        .form-group {
          margin-bottom: 15px;

          label {
            font-weight: bold;
            margin-bottom: 5px;
          }

          input,
          textarea {
            width: 100%;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          .error {
            color: $primary-color;
          }
        }
        .button {
          display: flex;
          justify-content: flex-end;
          @media(max-width:490px){
            justify-content: center;
          }

          button {
            background-color: $primary-color;
            color: #fff;
            padding: 10px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            width: 120px;
            transition: background-color 0.3s;

            &:hover {
              background-color: #555;
            }
            @media(max-width:490px){
              width: 80%;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

