$primary-color: #3498db;
$secondary-color: #ffffff;
$footer-bg-color: #2c3e50;
$newsletter-bg-image: url('../../utils/img//Law-Firm-Home-Page-pic-no-watermark-1-scaled.jpeg'); // Replace with the actual image path

.main-footer {
  background-color: $footer-bg-color;
  color: $secondary-color;
  text-align: center;
  font-size: larger;
  bottom: 0;
  width: 100%;
}


.newsletter-section {
  background: $newsletter-bg-image center/cover no-repeat;
  
  height: 30rem;

  .newsletter-container {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1{
      color: $primary-color;
      margin-bottom: 10px;
margin-top: 12rem;
    }

    form {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width :480px){
        flex-wrap: wrap;
      }
      input {
        padding: 10px;
        font-size: 14px;
        border: none;
        border-radius: 5px;
        margin-right: 10px;
        width: 20rem;
      }
      button {
        @media (max-width :480px){
          margin-top: 20px;
        }
        background-color: $primary-color;
        color: $secondary-color;
        padding: 10px 15px;
        font-size: 14px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}

.footer-content {
  @media (max-width :480px){
    flex-wrap: wrap;
  }
    display: flex;
    justify-content: space-around;
  
    .footer-section {
      max-width: 300px;
  
      h3 {
        margin-bottom: 10px;
      }
  
      p {
        font-size: 14px;
        margin-bottom: 10px;
      }
  
      ul {
        list-style: none;
        padding: 0;
  
        li {
          margin-bottom: 5px;
  
          a {
            color: $secondary-color;
            text-decoration: none;
          }
          a:hover{
            color: $primary-color;
          }
        }
      }
    }
}
