.main-content{
    // height: ;
    .area{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 50px;
        @media (max-width:1200px){
            margin-top: 10px;
                   
            }
        p{
            width: 700px;
            font-size: 20px ;
            text-align: justify;
            @media(max-width:1480px){
                width: 60%;
                  
            
                    }
                    @media(max-width:560px){
                        width: 80%;
                      
                          
                    
                            }
                            @media(max-width:520px){
                                width: 85%;
                              text-align: left;
                                  
                            
                                    }
        }
    }
    .count-main{
        display: flex;
        padding: 20px;
        padding-left: 110px;
        flex-wrap: wrap;
        position: absolute;
        justify-content: center;
        align-items: center;
        
            top: 25rem;
           @media(max-width:457px){
            padding-left: 30px;
           }
        .count1{
            width: 250px;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            margin-right: 30px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background-color: white;
            border-radius: 4px;
            @media(max-width:1480px){
                margin-top: 50px;
        
                }
            h1{
                padding-top: 20px;
            }
            h3{
                padding: 30px;
                border-top: 5px solid rgb(255, 60, 0);
            }
        }
    }
    .section{
        margin-top: 50px;
            
        display: flex;
        justify-content: space-around;
       flex-wrap: wrap;
       
        .img-section{
            display: flex;
            justify-content: center;
            align-items: center;
          img{
            width: 55rem;
            height: 80%;
            @media(max-width:911px){
                width: 80%;
            }
          }
        }
        .content-section{
            width: 400px;
         margin-top: 10%;
       
            @media(max-width:1280px){
     width: 60%;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            text-align: justify;
            }
            p{
                font-size: large;
            }
            @media(max-width:460px){
                width: 80%;
            }
           
        }
    }
    .card-main{
        display: flex;
        padding: 20px;
       
        flex-wrap: wrap;
        justify-content: center;
       align-items: center;
       .card4{
        @media(max-width:728px){
            width: 100%;
          }
         
            
            width: 257px;
            height: 229px;
            margin: 2%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgb(223, 223, 223);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            img{
                width: 257px;
                @media(max-width:728px){
                    padding: 5%;
                  }
                  @media(max-width:450px){
                  width: 60%;
                  }
            }
            p{
                padding: 10px;
                margin-left: 20px;
                text-align: justify;
              
            }
            &:hover{
                background-color: rgb(244, 243, 243);
            }
        
       }
        .card1,.card2,.card3{
          @media(max-width:728px){
            width: 100%;
          }
         
            width: 250px;
            margin: 2%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgb(223, 223, 223);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            img{
                width: 250px;
                @media(max-width:728px){
                    padding: 5%;
                  }
                  @media(max-width:450px){
                  width: 60%;
                  }
            }
            p{
                padding: 10px;
                margin-left: 20px;

            }
            &:hover{
                background-color: rgb(244, 243, 243);
            }
        }
    }
    .attorneys{
        .heading{
            
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-top: 50px;
                p{
                    width: 700px;
                    font-size: 20px ;
                    text-align: justify;
                    @media(max-width:1480px){
                    width: 60%;
                      
                
                        }
                         @media(max-width:560px){
                        width: 80%;
                      
                          
                    
                            }
                            @media(max-width:520px){
                                width: 85%;
                              text-align: left;
                                  
                            
                                    }
                }
            
        }
    
    .attr-main{
        display: flex;
        padding: 20px;
        margin: 50px;
        flex-wrap: wrap;
       justify-content: center;
       align-items: center;
        .attr-card1{
            @media(max-width:728px){
                width: 100%;
                margin-top: 10px;
              }
            width: 280px;
            margin: 1.2%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgb(223, 223, 223);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            img{
                width: 280px;
                height: 250px;
                @media(max-width:728px){
                    padding: 10px;
                  }
                @media (max-width:1480px){
                    width: 250px;
                   }
                   @media(max-width:460px){

                   width: 200px;
                   height: 200px;
                   }
            }
            p{
                padding: 10px;
                margin-left: 20px;
            }
            &:hover{
                background-color: rgb(244, 243, 243);
            }  
        }
    }
}
}

  
  
   
 
  
  
