$primary-color: #3498db;

.service-page {
    text-align: center;
    // padding: 20px;
  
    .cover-image {
        background: url("../../utils/img/brkmarketing-create-an-image-of-a-judges-gavel-5811709e-c8dc-481a-9139-8e788597f33f-1-770x418.png") center/cover no-repeat;

        width: 100vw;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        h1{
            color:$primary-color ;
            font-size: 70px;
            transition: transform 0.3s ease-in-out;
            &:hover {
                transform: scale(1.05);
              }
          }
    }
  
    .practice-area {
      max-width: 800px;
      margin: 0 auto;
      @media(max-width:480px){
        max-width: 300px;
      }
    }
  
    .page-title {
      font-size: 36px;
      font-weight: 900;
      margin-bottom: 20px;
    }
  
    .intro-text {
      font-size: 18px;
      margin-bottom: 40px;
      @media(max-width:480px){
      font-size: 14px;
      }
    }
  
    .practice-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  
      .practice-card {
        width: 300px;
        margin: 20px;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        cursor: pointer;
  
        img {
          width: 100%;
          max-height: 150px;
          object-fit: cover;
          border-radius: 8px;
          margin-bottom: 10px;
        }
  
        h3 {
          font-size: 24px;
          margin-bottom: 10px;
        }
  
        p {
          font-size: 16px;
          color: #555;
        }
      }
    }
  }
  